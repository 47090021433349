import styled from '@emotion/styled';
import React from 'react';
import { theme } from 'styles/theme';

const Root = styled.aside<{ showVideo?: boolean }>`
  display: ${({ showVideo }) => (showVideo ? 'flex' : 'none')};
  justify-content: center;
  ${{
    [theme.mediaQueries.md]: {
      display: 'flex',
    },
  }}
  /* margin: 2rem 0; */
  max-height: 50rem;
`;

const Video = styled.video`
  max-width: 100%;
`;

interface Props {
  videoSrc: string;
  showVideo?: boolean;
}

export const DemoVideo = ({ videoSrc, showVideo }: Props) => {
  return (
    <Root showVideo={showVideo}>
      <Video autoPlay muted loop>
        <source src={videoSrc} type="video/webm" />
        <p>
          Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
          <a href="#">un lien pour télécharger la vidéo</a>
        </p>
      </Video>
    </Root>
  );
};
