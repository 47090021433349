import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const TitleH3 = styled.h3<{ color?: string }>`
  font-family: ${theme.fontsFamily.magistral.italic};
  font-size: 15px;
  text-align: center;
  font-weight: 300;
  ${{
    [theme.mediaQueries.md]: {
      fontSize: '25px',
      lineHeight: '46px'
    }
  }}
  color: ${({ color }) => color || theme.colors.darkBlue};
`;
