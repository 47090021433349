import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Section } from './Section';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';

const Root = styled.div`
  display: none;
  ${{
    [theme.mediaQueries.md]: {
      display: 'block',
    },
  }}
`;

export const Section3 = () => {
  const { t } = useTranslation('mindsapp');
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (Array.isArray(t('section3.sections', { returnObjects: true }) as [])) {
      setSections(t('section3.sections', { returnObjects: true }) as []);
    }
  }, []);

  return (
    <Root id="section-3">
      {sections.map((props, index) => (
        <Section key={index} {...props} index={index} />
      ))}
    </Root>
  );
};
