import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import slugify from 'slugify';
import { ScrollButton } from 'components/commons/ScrollButton';

const Root = styled.nav`
  margin-top: 6rem;
  padding-bottom: 4rem;
  display: none;
  ${{
    [theme.mediaQueries.md]: {
      display: 'block',
    },
  }}
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 15rem;
`;

const Link = styled.div`
  background-color: ${theme.colors.orange};
  height: 10rem;
  width: 10rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  color: white;
  cursor: pointer;
  transition: all 150ms ease-in;
  & * {
    color: white;
  }
  &:hover {
    color: white;
    background-color: ${theme.colors.darkOrange};
  }
`;

const Word = styled.span`
  text-transform: uppercase;
  text-align: center;
  width: 14rem;
  color: white;
`;

const Icon = styled.img`
  width: 5rem;
`;
export const Logos = () => {
  const { t } = useTranslation('mindsapp');
  const [logos, setLogos] = useState([]);

  const handleScroll = (hash: string) => {
    const element = document.querySelector(hash);
    const rect = element.getBoundingClientRect();
    window.scroll({
      top: rect.top + window.pageYOffset - 110,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (Array.isArray(t('section2.logos', { returnObjects: true }) as [])) {
      setLogos(t('section2.logos', { returnObjects: true }) as []);
    }
  }, [t]);

  return (
    <Root id="section-2-logos">
      <List>
        {logos.map(({ icon, label }, index) => {
          return (
            <ListItem key={index}>
              <Link
                role="link"
                onClick={() =>
                  handleScroll(
                    `#${slugify(label, {
                      lower: true,
                      strict: false,
                    })}`
                  )
                }
              >
                <Icon src={icon} alt={label} />
              </Link>
              <Word>{label}</Word>
            </ListItem>
          );
        })}
      </List>
      <ScrollButton bottom="-5px" scrollTo={70} href="#section-3" />
    </Root>
  );
};
