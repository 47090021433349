import React, { useState } from 'react';
import { theme } from 'styles/theme';
import hexToRgba from 'hex-to-rgba';
import styled from '@emotion/styled';
import { Button } from 'components/commons/Button';
import { ModalVideo } from './ModalVideo';
import { Section as SectionTag } from 'components/commons/Section';
import slugify from 'slugify';
import { TitleH3 } from 'components/Typo/TitleH3';
import { Text } from 'components/Typo/Text';
import { TitleH2WithChevron } from '../TitleH2WithChevron';
import Link from 'next/link';

const CustomSection = styled(SectionTag)<{ isEven: boolean }>`
  position: relative;
  ${{
    [theme.mediaQueries.md]: {
      padding: '7rem 15rem',
    },
  }}
  &:not(:last-of-type) {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: ' ';
      height: 3px;
      background: linear-gradient(
          to ${({ isEven }) => (isEven ? 'left' : 'right')},
          ${hexToRgba(theme.colors.orange, 0)} 30%,
          ${hexToRgba(theme.colors.orange, 100)} 100%
        )
        ${({ isEven }) => (isEven ? 'left' : 'right')} bottom white no-repeat;
      background-size: 100% 5px;
    }
  }
`;

const CustomText = styled(Text)`
  margin: 1rem 0 3rem 0;
  ${{
    [theme.mediaQueries.md]: {
      maxWidth: '35rem',
    },
  }}
  & a {
    text-decoration: underline;
  }
  & a:hover {
    color: ${theme.colors.darkBlue};
  }
`;

const Image = styled.img`
  max-width: 100%;
  ${{
    [theme.mediaQueries.md]: {
      height: '250px',
    },
  }}
`;

const Aside = styled.aside`
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
interface Props {
  title: string;
  buttonText: string;
  paragraph: string;
  subTitle: string;
  video: string;
  index: number;
  capture: string;
}

export const Section = ({
  title,
  buttonText,
  paragraph,
  subTitle,
  video,
  capture,
  index,
}: Props) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomSection
      id={slugify(title, {
        lower: true,
        strict: false,
      })}
      flexDirection={index % 2 === 0 ? 'row' : 'row-reverse'}
      isEven={index % 2 === 0}
    >
      <Aside>
        <TitleH2WithChevron title={title} />
        <TitleH3>{subTitle}</TitleH3>
        <CustomText dangerouslySetInnerHTML={{ __html: paragraph }} />
        <Link href="/contact">
          <Button>{buttonText}</Button>
        </Link>
        {/* <Button onClick={handleOpen} hover={theme.colors.lightBlue}>
          {buttonText}
        </Button> */}
      </Aside>
      <Aside>
        <Image src={capture} alt={title} />
      </Aside>
      {open && <ModalVideo open={open} onClose={handleClose} video={video} />}
    </CustomSection>
  );
};
