import styled from '@emotion/styled';
import React from 'react';
import { theme } from 'styles/theme';
import { useTranslation } from 'next-i18next';
import { DemoVideo } from './DemoVideo';
import { Logos } from './Logos';
import { Section } from 'components/commons/Section';
import { ScrollButton } from 'components/commons/ScrollButton';
import { Text } from 'components/Typo/Text';
import { TitleH2 } from 'components/Typo/TitleH2';
import { CarousselLogos } from './CarousselLogos';

const CustomSection = styled(Section)`
  padding: 0;
  background-color: ${theme.colors.background.darkBlue};
  flex-direction: column;
  align-items: center;
  padding: 6rem 0;
  ${{
    [theme.mediaQueries.md]: {
      margin: '-0.5rem 0 0 0',
    },
  }}
`;

const KeyWords = styled(TitleH2)`
  color: ${theme.colors.orange};
  font-family: ${theme.fontsFamily.magistral.italic};
  margin-bottom: 1rem;
  text-transform: none;
`;

const Header = styled.header`
  padding: 0 3rem;
`;

const Sentence = styled(Text)`
  color: white;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  font-weight: 300;
  ${{
    [theme.mediaQueries.md]: {
      fontSize: '2rem',
      padding: 0,
    },
  }}
`;

export const Section2 = () => {
  const { t } = useTranslation('mindsapp');
  return (
    <CustomSection id="video-mindsapp">
      <Header>
        <KeyWords>{t('section2.keyWords', { joinArrays: ', ' })}</KeyWords>
        <Sentence>{t('section2.subTitle')}</Sentence>
      </Header>
      <DemoVideo videoSrc="assets/video/Final.webm" />
      <ScrollButton
        position="relative"
        scrollTo={170}
        href="#section-2-logos"
        bottom="-5px"
      />
      <CarousselLogos />
      <Logos />
    </CustomSection>
  );
};
