import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { theme } from 'styles/theme';
import { TitleH2 } from 'components/Typo/TitleH2';
import { TitleH3 } from 'components/Typo/TitleH3';
import { Text } from 'components/Typo/Text';
import { Button } from 'components/commons/Button';
import { FlexBox } from 'components/commons/FlexBox';
import { ModalVideo } from '../Section3/ModalVideo';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${{
    [theme.mediaQueries.md]: {
      display: 'none',
    },
  }}
`;

const Viewer = styled.div`
  position: relative;
  overflow: hidden;
  height: 70vh;
  width: 75vw;
`;

const Card = styled.div<{ position: number }>`
  left: ${({ position }) => position * 75}vw;
  height: 70vh;
  width: 75vw;
  background-color: ${theme.colors.white};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
`;
const CustomText = styled(Text)`
  margin: 1rem 0 3rem 0;
  ${{
    [theme.mediaQueries.md]: {
      maxWidth: '35rem',
    },
  }}
  & a {
    text-decoration: underline;
  }
  & a:hover {
    color: ${theme.colors.darkBlue};
  }
`;
const ChevronLeft = styled.img<{
  direction: string;
  right?: string;
  left?: string;
}>`
  transform: rotate(${({ direction }) => direction}) translateY(-50%);
  position: absolute;
  width: 40px;
  margin: 0 1rem;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  top: 40%;
  z-index: 5;
  cursor: pointer;
`;

const ChevronRight = styled(ChevronLeft)<{
  direction: string;
  right?: string;
  left?: string;
}>`
  transform: rotate(${({ direction }) => direction}) translateY(50%);
  position: absolute;
  width: 40px;
  margin: 0 1rem;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  top: 40%;
`;

const Image = styled.img`
  max-width: 100%;
  height: 100px;
`;
const DotsWrapper = styled.div`
  display: flex;
  margin-top: 4rem;
`;

const Dot = styled.span<{ isSelected: boolean }>`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 4rem;
  margin: 0.5rem;
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.orange : 'gray'};
`;

const Scene = styled.div<{ cursor: number }>`
  transition: all 150ms ease-out;
  transform: ${({ cursor }) => `translateX(-${cursor * 75}vw)`};
`;

export const CarousselLogos = () => {
  const { t } = useTranslation('mindsapp');
  const [tiles, setTiles] = useState<any[]>([]);
  const [cursor, setCursor] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (Array.isArray(t('section3.sections', { returnObjects: true }) as [])) {
      setTiles(t('section3.sections', { returnObjects: true }) as []);
    }
  }, []);

  const handlePrevNav = () => {
    setCursor((prevCursor) => {
      if (prevCursor !== 0) {
        return prevCursor - 1;
      }
      return tiles.length - 1;
    });
  };

  const handleNextNav = () => {
    setCursor((prevCursor) => {
      if (prevCursor < tiles.length - 1) {
        return prevCursor + 1;
      }
      return 0;
    });
  };

  const handleDotClick = (pos: number) => {
    setCursor(pos);
  };

  return (
    <Root>
      <ChevronLeft
        alt="Navigation icon"
        src="/assets/pictos/chevron.svg"
        direction="0deg"
        left="-4rem"
        onClick={handlePrevNav}
      />
      <Viewer>
        <Scene cursor={cursor}>
          {tiles.map(
            (
              { title, buttonText, paragraph, subTitle, video, capture }: any,
              index
            ) => (
              <Card key={index} position={index}>
                <Image src={capture} alt={title} />
                <FlexBox flexDirection="column">
                  <TitleH2>{title}</TitleH2>
                  <TitleH3>{subTitle}</TitleH3>
                </FlexBox>
                {/* <Text>{paragraph}</Text> */}
                <CustomText dangerouslySetInnerHTML={{ __html: paragraph }} />
                <Button
                  onClick={() => setOpen(true)}
                  hover={theme.colors.lightBlue}
                >
                  {buttonText}
                </Button>
                {open && (
                  <ModalVideo open={open} onClose={handleClose} video={video} />
                )}
              </Card>
            )
          )}
        </Scene>
      </Viewer>
      <ChevronRight
        alt="Navigation icon"
        src="/assets/pictos/chevron.svg"
        direction="180deg"
        right="-4rem"
        onClick={handleNextNav}
      />
      <DotsWrapper>
        {tiles.map((_, index) => (
          <Dot
            key={index}
            isSelected={cursor === index}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </DotsWrapper>
    </Root>
  );
};
