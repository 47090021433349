import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styled from '@emotion/styled';
import { DemoVideo } from '../Section2/DemoVideo';
import { theme } from 'styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const Wrapper = styled.div`
  ${{
    [theme.mediaQueries.md]: {
      width: '800px',
    },
  }}
`;

const CloseButton = styled.button`
  z-index: 1;
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 3px;
  width: 3rem;
  height: 3rem;
  border: none;
  background-color: ${theme.colors.blueGreen};
  outline: none;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.orange};
  }
`;

export const ModalVideo = ({ open, onClose, video }) => {
  const classes = useStyles();
  return (
    <dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            backgroundColor: 'rgba(0,0,0,0.2)',
          },
        }}
      >
        <Fade in={open}>
          <Wrapper className={classes.paper}>
            <CloseButton onClick={onClose}>X</CloseButton>
            <DemoVideo showVideo videoSrc={video} />
          </Wrapper>
        </Fade>
      </Modal>
    </dialog>
  );
};
