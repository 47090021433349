import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { Button } from 'components/commons/Button';
import { theme } from 'styles/theme';
import { Section } from 'components/commons/Section';
import { TitleH2 } from 'components/Typo/TitleH2';
import Link from 'next/link';

const Root = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.orange};
  padding: 1rem;
  ${{
    [theme.mediaQueries.md]: {
      padding: '3rem',
    },
  }}
`;

const CustomButton = styled(Button)`
  & a {
    color: white;
    text-transform: none !important;
  }
`;

const CustomTitleH2 = styled(TitleH2)`
  margin: 1rem 0;
  /* font-size: 25px; */
  ${{
    [theme.mediaQueries.md]: {
      fontSize: '25px',
    },
  }}
`;

export const Section4 = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <CustomTitleH2>{t('footer.haveQuestions')}</CustomTitleH2>
      <CustomButton
        backgroundColor={theme.colors.blueGreen}
        hover={theme.colors.darkBlue}
      >
        <Link href="/contact">{t('footer.contact')}</Link>
      </CustomButton>
    </Root>
  );
};
