import styled from '@emotion/styled';
import { FlexBox } from 'components/commons/FlexBox';
import { TitleH2 } from 'components/Typo/TitleH2';
import React from 'react';

const Chevron = styled.img<{ direction: string }>`
  transform: rotate(${({ direction }) => direction});
  width: 50px;
  margin: 0 1rem;
`;

interface Props {
  title: string;
}

export const TitleH2WithChevron = ({ title }: Props) => {
  return (
    <FlexBox alignItems="center">
      <Chevron
        alt="Navigation icon"
        src="/assets/pictos/chevron.svg"
        direction="0deg"
      />
      <TitleH2>{title}</TitleH2>
      <Chevron
        alt="Navigation icon"
        src="/assets/pictos/chevron.svg"
        direction="180deg"
      />
    </FlexBox>
  );
};
