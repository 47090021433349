import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const SubTitle = styled.h2<{ color?: string }>`
  text-transform: uppercase;
  font-family: ${theme.fontsFamily.montserrat.name};
  font-weight: ${theme.fontsFamily.montserrat.regular};
  font-size: 15px;
  ${{
    [theme.mediaQueries.md]: {
      fontSize: '35px',
      lineHeight: '45px',
      color: theme.colors.darkBlue
    }
  }}
  color: ${({ color }) => color || theme.colors.white};
  text-align: center;
`;
