import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'react-i18next';
import { GetStaticPropsType } from 'types/globals.type';
import Head from 'next/head';
import Layout from 'components/Layout/Layout';
import { MindsApp } from 'components/MindsApp';
import { defaultTranslationImports } from '../utils/defaultTranslationImports';

const MindsAppPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{`${t('header.mindsapp')} - ${t('header.home')}`}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content="Mindsapp by Eukleia - Plateforme applicative - innovante - Performante - Evolutive - Pour créer votre solution sur-mesure lowcode"
        />
        <meta
          name="google-site-verification"
          content="c3swtPKMFtFWlhIqGckN7SmUNJ24h4gBJVnJqWCZblg"
        />
      </Head>
      <Layout defaultHeader={false}>
        <MindsApp />
      </Layout>
    </>
  );
};

export const getStaticProps = async ({
  locale,
}: Record<string, any>): Promise<GetStaticPropsType> => ({
  props: {
    ...(await serverSideTranslations(locale, [...defaultTranslationImports])),
  },
});

export default MindsAppPage;
