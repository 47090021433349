import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { theme } from 'styles/theme';
import { Button } from 'components/commons/Button';
import { ScrollButton } from 'components/commons/ScrollButton';
import Image from 'next/image';
import { SubTitle } from 'components/Typo/SubTitle';
import { useRouter } from 'next/router';

const Root = styled.header`
  display: grid;
  grid-template-areas: 'title' 'pictos';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: ${theme.colors.background.darkBlue};
  background: linear-gradient(
    180deg,
    rgba(19, 101, 175, 1) 0%,
    rgba(14, 62, 103, 1) 100%
  );
  margin-top: -10rem;
  ${{
    [theme.mediaQueries.md]: {
      gridTemplateAreas: `'title pictos'`,
      gridTemplateColumns: '1fr 1fr',
      // gridTemplateRows: '85vh',
      backgroundColor: theme.colors.white,
      marginTop: 'unset',
      background: 'unset',
    },
  }}
  flex: 1;
  /* height: 80vh; */
  position: relative;
`;

const Section1 = styled.section`
  grid-area: title;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  margin-top: 15rem;
  ${{
    [theme.mediaQueries.md]: {
      marginTop: '10rem',
      backgroundColor: 'transparent',
    },
  }}
`;

const Annotation = styled.span`
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
  padding: 0 2rem;
  ${{
    [theme.mediaQueries.md]: {
      color: theme.colors.darkBlue,
      fontSize: '2.7rem',
      padding: '1rem 2rem',
      lineHeight: '3.3rem',
    },
  }}
`;

const Aside = styled.aside`
  display: grid;
  grid-area: pictos;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'cloud';
  justify-content: center;
  min-height: 30rem;
  ${{
    [theme.mediaQueries.md]: {
      gridTemplateAreas: `'cloud .' '. mindsapp'`,
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      padding: '10rem 5rem 5rem 10rem',
      clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 20% 100%)',
      backgroundColor: theme.colors.background.darkBlue,
      background: `linear-gradient(
        180deg,
        rgba(19, 101, 175, 1) 0%,
        rgba(14, 62, 103, 1) 100%
      )`,
    },
  }}
  flex: 1;
`;

const Wrapper = styled.div<{ area: string; hiddenOnSmallDevice?: boolean }>`
  grid-area: ${({ area }) => area};
  height: 30rem;
  padding-top: 3rem;
  display: ${({ hiddenOnSmallDevice }) =>
    hiddenOnSmallDevice ? 'none' : 'flex'};
  justify-content: center;
  ${({ hiddenOnSmallDevice }) => ({
    [theme.mediaQueries.md]: {
      display: 'flex',
      alignItems: !hiddenOnSmallDevice ? 'center' : 'flex-start',
    },
  })}
`;

const CustomButton = styled(Button)`
  background-color: ${theme.colors.orange};
  border-radius: 10rem;
  margin: 3rem;
  &:active {
    background-color: ${theme.colors.blueGreen};
  }
  ${{
    [theme.mediaQueries.md]: {
      margin: '2rem',
      borderRadius: '10px',
      padding: '1rem 4rem',
      fontSize: '3rem',
    },
  }}
`;

export const Header = () => {
  const router = useRouter();
  const { t } = useTranslation('mindsapp');
  return (
    <Root>
      <Section1>
        <SubTitle>{t('header.title')}</SubTitle>
        <div style={{ marginTop: '2rem' }} />
        <SubTitle>{t('header.subtitle.0')}</SubTitle>
        <SubTitle>{t('header.subtitle.1')}</SubTitle>
        <SubTitle>{t('header.subtitle.2')}</SubTitle>
        <div style={{ marginTop: '2rem' }} />
        <SubTitle>{t('header.subtitle.3')}</SubTitle>
        <SubTitle>{t('header.subtitle.4')}</SubTitle>
        <CustomButton
          margin="2rem"
          padding="1rem 2rem"
          backgroundColor={theme.colors.blueGreen}
          borderRadius="17px"
          fontSize="1.7rem"
          // hover={theme.colors.orange}
          onClick={() => router.push('/contact')}
        >
          {t('header.demo')}
        </CustomButton>
        <Annotation>{t('header.annotation')}</Annotation>
      </Section1>
      <Aside>
        <Wrapper area="cloud">
          <Image
            src="/assets/pictos/bonhomme_nuage.svg"
            alt="Could man"
            width={225}
            height={225 / 1.18}
            quality={100}
            priority
            layout="fixed"
          />
        </Wrapper>
        <Wrapper area="mindsapp" hiddenOnSmallDevice>
          <Image
            src="/assets/pictos/mindsAppWhiteLogo.svg"
            alt="Mindsapp icon"
            width={225}
            height={225 / 0.97}
            quality={100}
            priority
            layout="fixed"
          />
        </Wrapper>
      </Aside>
      <ScrollButton scrollTo={80} href="#video-mindsapp" />
    </Root>
  );
};
