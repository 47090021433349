import styled from '@emotion/styled';
import React from 'react';
import { Header } from './Header/Header';
import { Section2 } from './Section2/Section2';
import { Section3 } from './Section3/Section3';
import { Section4 } from './Section4/Section4';
import { Article } from 'components/commons/Article';

const Root = styled(Article)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface Props {}

export const MindsApp = (props: Props) => {
  return (
    <Root>
      <Header />
      <Section2 />
      <Section3 />
      <Section4 />
    </Root>
  );
};
